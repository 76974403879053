<template>
  <div class="pricing">
    <home-header></home-header>
    <div class="contentWrap">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
  import Vuex from 'vuex'
  import Header from '@/components/Header.vue'

  export default {
    components: {
      'home-header': Header
    },
    computed: {
      ...Vuex.mapGetters(['isMember'])
    },
    watch: {
      isMember: {
        handler: function(val) {
          if (val) this.$router.push('/clips')
        },
        immediate: true
      }
    }
  }
</script>

<style lang="scss" scoped>
  .pricing {
    .contentWrap {
      box-sizing: border-box;
      padding-top: 80px;
      width: 100%;
      min-height: 100vh;
      background: #0A189D;
    }
  }
</style>